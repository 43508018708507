import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MonitorLaunchErrorValueFragmentDoc } from './monitorLaunchErrorValue.graphql';
import { MonitorLaunchResultValueFragmentDoc } from './monitorLaunchResultValue.graphql';
import { MonitorLaunchCalculatingValueFragmentDoc } from './monitorLaunchCalculatingValue.graphql';
export type MonitorEntryFragment = { __typename: 'MonitorEntry', id: string, name: string, target?: number | null, calculationMethod: Types.CalculationMethodEnum, monitorGroup: { __typename: 'MonitorGroup', id: string }, leftBlueprint: { __typename: 'Blueprint', id: string }, leftBlueprintSourceField?: { __typename: 'SourceField', id: string } | null, rightBlueprint?: { __typename: 'Blueprint', id: string } | null, values: Array<{ __typename: 'MonitorLaunchCalculatingValue', monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } } | { __typename: 'MonitorLaunchResultValue', value: number, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } } | { __typename: 'MonitorLaunchErrorValue', message: string, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } }> };

export const MonitorEntryFragmentDoc = gql`
    fragment MonitorEntry on MonitorEntry {
  __typename
  id
  name
  target
  calculationMethod
  monitorGroup {
    __typename
    id
  }
  leftBlueprint {
    __typename
    id
  }
  leftBlueprintSourceField {
    __typename
    id
  }
  rightBlueprint {
    __typename
    id
  }
  values {
    ... on MonitorLaunchErrorValue {
      ...MonitorLaunchErrorValue
    }
    ... on MonitorLaunchResultValue {
      ...MonitorLaunchResultValue
    }
    ... on MonitorLaunchCalculatingValue {
      ...MonitorLaunchCalculatingValue
    }
  }
}
    ${MonitorLaunchErrorValueFragmentDoc}
${MonitorLaunchResultValueFragmentDoc}
${MonitorLaunchCalculatingValueFragmentDoc}`;